import React, { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RecoilRoot } from 'recoil';

import i18n from '@/application/i18n';
import { DayJsLocalization } from '@/application/layouts/DayJsLocalization';

function AppContext({ children }: { children: ReactNode }) {
  return (
    <RecoilRoot>
      <DayJsLocalization>
        <I18nextProvider i18n={i18n}>
          {children}
        </I18nextProvider>
      </DayJsLocalization>
    </RecoilRoot>
  );
}

export default AppContext;
