import React, { ComponentType, ReactNode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppContext from './AppContext';

function Layout({ children }: { children: ReactNode }) {
  return (
    <AppContext>
      <Router>
        {children}
      </Router>
    </AppContext>
  );
}

/**
 * Wraps a component within a Layout
 * @param Component
 */
// eslint-disable-next-line func-names
export const withLayout = <P extends object>(Component: ComponentType<P>) => function (props) {
  return <Layout><Component {...props as P} /></Layout>;
};

export default Layout;
