import { atom, selector } from 'recoil';

import parseExportedParams from '@/shared/exportedParams';

interface ExportedParams {
  user_id?: string
  userId?: string
  locale?: string
  features?: string[]
  agencyName?: string
  agencyShortName?: string
  agencyEmail?: string
  agencyPhoneNumber?: string
  agencyLogoUrl?: string
  agencyTimeZone?: string
  env?: string
  release?: string
}

export const exportedParams = atom<ExportedParams>({
  key: 'exportedParams',
  default: parseExportedParams(),
});

export const timeZone = selector({
  key: 'timeZone',
  get: ({ get }) => get(exportedParams).agencyTimeZone || 'Europe/Amsterdam',
});

export const userLocale = selector({
  key: 'userLocale',
  get: ({ get }) => get(exportedParams).locale || 'nl',
});

export const features = selector({
  key: 'features',
  get: ({ get }) => get(exportedParams).features || [],
});
