import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import timezonePlugin from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { userLocale } from '@/application/atoms/exports';

dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezonePlugin);

export function DayJsLocalization({ children }: { children: ReactNode }) {
  const locale = useRecoilValue(userLocale);
  dayjs.locale(locale);

  return children;
}
